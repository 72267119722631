import React, { Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Container, Grid, Paper, Tabs, Tab, Typography, Box, List, ListItem, ListItemText} from '@material-ui/core';

const Styles = ({
  root: {
    width: '100vw',
    padding: '4rem 0 4rem 0', 
  },
  grid: {
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center',
    },
    paper: {
        flexGrow: 1,
        marginTop: '2rem',
    },
});

const pastProjects = [
    'Live Chat Application',
    'Driving Instructor Booking System'
]

const pastProjectsTech = [
    'React.js',
    'Redux',
    'Node.js',
    'postgreSQL',
    'Google Firebase',
    'Material-UI'
]

const workOutGamesTasks = [
    'To build fully functional greenfield web applications using React.js',
    'To have Working knowledge of GIT, Bootstrap and Material UI styling libraries.',
    'To build RESTful API services using Node.js & Express.js along with data-handling with PostgreSQL and Google Firebase (No SQL)'
]

const gbmpTasks = [
    'Website Design, Development & Maintenance using HTML, CSS and JavaScript',
    'Video Production', 
    'Storyboarding', 
    'Visual FX', 
    'Character Animation', 
    'Promotional Videos',
    'Logo Design & Branding', 
    'Social Media Marketing', 
    'Scheduling Budgets', 
    'Defining Budget Constraints'
]

const gDesignerFreelance = [
    'Logo Design',
    '3D Asset Creation',
    'Website Design',
    'Wordpress'
]

const TabPanel = props => {
    const { children, value, index } = props;

    return( 
    <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        >
        {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

class Navigation extends React.Component {
    constructor(){
        super()
        this.state = {
            value: 0
        }
    }

    componentDidMount = () => {
        this.props.getCurrentRoute(this.props.location.pathname)
    }

    onHandleChange = (e, newValue) => {
        this.setState({value: newValue})
    }
    render(){

    const { classes } = this.props;
    const { value } = this.state;

    const pastProjectSecondary = pastProjects.map(item => {
        return(
            <Fragment key={item}>
            {item} <br/>
            </Fragment>
        )
    })

    const pastProjectTechSecondary = pastProjectsTech.map(item => {
        return(
            <Fragment key={item}>
            {item} <br/>
            </Fragment>
        )
    })

    const workOutGamesTasksSecondary = workOutGamesTasks.map(item => {
        return(
            <Fragment key={item}>
                {item} <br/><br/>
            </Fragment>
        )
    })

    const gbmpTasksSecondary = gbmpTasks.map(item => {
        return(
            <Fragment key={item}>
                {item}<br/><br/>
            </Fragment>
        )
    })


    const gDesignerFreelanceSecondary = gDesignerFreelance.map(item => {
        return(
            <Fragment key={item}>
                {item}<br/><br/>
            </Fragment>
        )
    }) 

    const currentProjectSecondary = 'Real-Time Transport Information Application for Taxi Drivers'


        return (
            <div className={classes.root}>
                <Container>
                    <Grid container className={classes.grid}>
                        <Grid item xs={12}>
                            <Typography variant="h3" component="h5" style={{padding: '2rem'}}>EXPERIENCE</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <hr/>
                        </Grid>
                        <Grid item md={8} xs={12}>
                            <Paper className={classes.paper} >
                                <Tabs
                                    value={value}
                                    onChange={this.onHandleChange}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    centered
                                >
                                    <Tab label="Freelance"/>
                                    <Tab label="WorkOut Games" />
                                    <Tab label="GBMP" />
                                    <Tab label="Freelance" />
                                </Tabs>
                            </Paper>
                        </Grid>
                            <Grid item md={8} xs={12} >
                                <TabPanel value={value} index={0}>
                                    <Grid container style={{textAlign: 'center'}}>
                                        <Paper elevation={1} style={{width: '100vw'}}>
                                            <Typography style={{textTransform: 'uppercase', marginTop: '1.5rem'}} variant='h6'>Web Developer - React</Typography>
                                            <Typography style={{fontStyle: 'italic'}} variant='button'>March 2019 - Current</Typography>
                                            <hr style={{width: '75%', marginTop: '1rem'}}/>
                                            <List>
                                                <ListItem>
                                                    <ListItemText primary='Current Projects' secondary={currentProjectSecondary} />
                                                </ListItem>
                                            </List>
                                            <List>
                                                <ListItem>
                                                    <ListItemText primary='Past Projects' secondary={pastProjectSecondary} />
                                                </ListItem>
                                            </List>
                                            <List>
                                                <ListItem>
                                                    <ListItemText primary='Technologies Used:' secondary={pastProjectTechSecondary} />
                                                </ListItem>
                                            </List>
                                        </Paper>
                                    </Grid>
                                </TabPanel>
                                <TabPanel value={value} index={1}>
                                    <Grid container style={{textAlign: 'center'}}>
                                        <Paper elevation={1} style={{width: '100vw'}}>
                                            <Typography style={{textTransform: 'uppercase', marginTop: '1.5rem'}} variant='h6'>Web Developer - React</Typography>
                                            <Typography style={{fontStyle: 'italic'}} variant='button'>Jan 2018 - March 2019</Typography>
                                            <hr style={{width: '75%', marginTop: '1rem'}}/>
                                            <List>
                                                <ListItem>
                                                    <ListItemText primary='Tasks' secondary={workOutGamesTasksSecondary} />
                                                </ListItem>
                                            </List>
                                        </Paper>
                                    </Grid>
                                </TabPanel>
                                <TabPanel value={value} index={2}>
                                    <Grid container style={{textAlign: 'center'}}>
                                        <Paper elevation={1} style={{width: '100vw'}}>
                                            <Typography style={{textTransform: 'uppercase', marginTop: '1.5rem'}} variant='h6'>Web Developer / Graphic Designer</Typography>
                                            <Typography style={{fontStyle: 'italic'}} variant='button'>Oct 2013 - Jan 2018</Typography>
                                            <hr style={{width: '75%', marginTop: '1rem'}}/>
                                            <List>
                                                <ListItem>
                                                    <ListItemText primary='Tasks' secondary={gbmpTasksSecondary} />
                                                </ListItem>
                                            </List>
                                        </Paper>
                                    </Grid>
                                </TabPanel>
                                <TabPanel value={value} index={3}>
                                <Grid container style={{textAlign: 'center'}}>
                                        <Paper elevation={1} style={{width: '100vw'}}>
                                            <Typography style={{textTransform: 'uppercase', marginTop: '1.5rem'}} variant='h6'>Graphic Designer</Typography>
                                            <Typography style={{fontStyle: 'italic'}} variant='button'>Mar 2012 - Oct 2013</Typography>
                                            <hr style={{width: '75%', marginTop: '1rem'}}/>
                                            <List>
                                                <ListItem>
                                                    <ListItemText primary='Tasks' secondary={gDesignerFreelanceSecondary} />
                                                </ListItem>
                                            </List>
                                        </Paper>
                                    </Grid>
                                </TabPanel>
                            </Grid>
                        </Grid>
                </Container>
            </div>
        );
    }
}

export default withStyles(Styles)(Navigation)