import React, { Component } from 'react';
import { BrowserRouter as Router, Route} from 'react-router-dom';
import * as ROUTES from './Contants/Routes';
import Home from './Components/Home';
import About from './Components/About';
import Skills from './Components/Skills'
import Experience from './Components/Experience';
import Portfolio from './Components/Portfolio/Portfolio'
import Contact from './Components/Contact';
import Navigation from './Components/Navigation';
import { Grid } from '@material-ui/core';
import BackgroundHome from './img/computers.png'
import NavArrows from './Components/NavArrows'


class App extends Component {
    constructor(){
        super()
        this.state =  {
            currentRoute: '/'
        }
    } 

    getCurrentRoute = currentRoute => {
        this.setState({currentRoute})
    }

    ifHome = currentRoute => {
        return currentRoute === '/'
    }

    
    render() {

        const { currentRoute } = this.state;

        return (
            <div style={ this.ifHome(currentRoute) ? {backgroundImage: `url(${BackgroundHome})`} :  {backgroundImage: 'none'} }>
              <Router>
                <Navigation/>
                    <Grid container  stlye={{marginTop: '3rem'}}>
                        <Grid item xs={12}>
                            <div style={{overflow: 'scroll'}}>
                                
                                <Route exact path='/' render={(props) => <Home {...props} getCurrentRoute={this.getCurrentRoute} />} />
                                <Route path={ROUTES.ABOUT} render={(props) => <About {...props} getCurrentRoute={this.getCurrentRoute}/>}/>
                                <Route path={ROUTES.SKILLS} render={(props) => <Skills {...props} getCurrentRoute={this.getCurrentRoute}/>}/>
                                <Route path={ROUTES.EXPERIENCE} render={(props) => <Experience {...props} getCurrentRoute={this.getCurrentRoute} />}/>
                                <Route path={ROUTES.PORTFOLIO} render={(props) => <Portfolio {...props} getCurrentRoute={this.getCurrentRoute} />}/>
                                <Route path={ROUTES.CONTACT} render={(props) => <Contact {...props} getCurrentRoute={this.getCurrentRoute} />}/>       
                            </div>
                        </Grid>
                    </Grid>
                    <NavArrows currentRoute={currentRoute}/>
              </Router>
            </div>
        )
    }
}

export default App