import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Container, Grid, Typography } from '@material-ui/core'; 
import LinearProgress from '@material-ui/core/LinearProgress';
import skillsData from '../Components/SkillsData';


const Styles = theme => ({
    root: {
        width: '100vw',
        padding: '4rem 0 4rem 0', 
        // overflow: 'scroll'
    },
    grid: {
        textAlign: 'center',
    },
    icon: {
        textTransform: 'uppercase',
        marginTop: '1rem',
        marginBottom: '1rem'
    },
    profilePic: {
        border: '10px solid gray',
        height: '250px',
        borderRadius: '50%',
        boxShadow: '0px 10px 10px -4px rgba(0,0,0,0.2), 0px 10px 12px 0px rgba(0,0,0,0.14), 0px 2px 16px 0px rgba(0,0,0,0.12)'
    },
    progessBar : {
        margin: '1rem',
        borderRadius: '5px',
    },
    PBroot: {
        height: 10,
        // backgroundColor: lighten('#ff6c5c', 0.5),
      },
    PBBar: {
        borderRadius: 20,
        // backgroundColor: '#ff6c5c',
    }
  });



 class Skills extends Component {

    componentDidMount = () => {
        this.props.getCurrentRoute(this.props.location.pathname)
    }

    render() {

        const {classes} = this.props;

        const renderSkills = skillsData.map(item => {
            return (
                <Grid key={item.id} item md={3} xs={6} style={{marginTop: '.5rem', padding: '.5rem'}}>
                    <Typography variant='button' >{item.type}</Typography>
                    <LinearProgress
                        className={classes.progessBar}
                        variant="determinate"
                        classes={{
                            root: classes.PBroot,
                            bar: classes.PBBar
                        }}
                        color='primary'
                        value={item.value}
                    />
                </Grid>
            )
        })

        return (
            <div className={classes.root}>
                <Container>
                    <Grid container className={classes.grid}>
                        <Grid item xs={12}>
                            <Typography variant="h3" component="h5" style={{padding: '2rem'}}>SKILLS</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <hr/>
                        </Grid>
                            {renderSkills}
                    </Grid>
                </Container>
            </div>
        )
    }
}

export default withStyles(Styles)(Skills)