import React, { Component } from 'react';
import { Container, Grid, Typography, Icon } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import HeartIcon from '@material-ui/icons/Favorite';
import CoffeeIcon from '@material-ui/icons/LocalDrink';
import AddIcon from '@material-ui/icons/Add';
import {withRouter } from 'react-router-dom';
import * as ROUTES from '../Contants/Routes'
// import withWidth, { isWidthDown } from '@material-ui/core/withWidth';


const Styles = theme => ({
    root: {
        width: '100vw',
    },
    rootDetail: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh',
      color: 'white'
    },
    topDetail: {
        border: '1px solid white',
        padding: '.75rem 1.5rem .75rem 1.5rem',
        borderTopLeftRadius: '20px',
        borderTopRightRadius: '20px',
        marginBottom: '1rem',
        width: '6rem',
        textAlign: 'center'
    },
    subtitle: {
        fontStyle: 'italic',
        textTransform: 'uppercase',
        marginTop: '.5rem'
    },
    bottomDetail: {
        border: '1px solid white',
        padding: '.75rem 1.5rem .75rem 1.5rem',
        borderBottomLeftRadius: '20px',
        borderBottomRightRadius: '20px',
        marginBottom: '1rem',
        marginTop: '1rem',
        width: '6rem',
        textAlign: 'center'
    },
    madeWith: {
        marginTop: '2rem',
        textTransform: 'uppercase',
        display: 'flex',
        alignItems: 'center'
    }
  });

class Home extends Component {
    constructor(){
        super()
        this.state =  {
            mountOn: true,
            mountOff: false
        }
    }
    
    componentDidMount = () => {
        this.props.getCurrentRoute(this.props.location.pathname)
    }

    learnMore = () => {
        this.props.history.push(ROUTES.ABOUT)
    }


    render() {
        const { classes } = this.props;
        // const isSmallScreen = isWidthDown('md', width)

        return (
            <div className={classes.root}>
                <Container>
                    <Grid container>
                        <div className={classes.rootDetail}>
                            <Typography className={classes.topDetail} variant="button" component="h5">HELLO I'M</Typography>
                            <Typography variant="h3" component="h1">ADEEL AHMAD</Typography>
                            <Typography className={classes.subtitle} variant="h6" component="h4">- "A Developer In Many Ways" -</Typography>
                            <Typography onClick={this.learnMore} className={classes.bottomDetail} variant="button" component="h6">Learn More</Typography>
                            <Typography className={classes.madeWith}>Made With 
                                <Icon style={{marginLeft: '.5rem'}}>
                                    <HeartIcon/></Icon>
                                <Icon>
                                    <AddIcon/>
                                </Icon>
                                <Icon>
                                    <CoffeeIcon/>
                                </Icon>
                            </Typography>
                            
                        </div>
                        
                    </Grid>
                    
                </Container>
                {/* </Swipeable> */}
                
            </div>
        )
    }
}

export default withRouter(withStyles(Styles)(Home));