import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Container, Grid, Typography, Button } from '@material-ui/core'; 
import Data from '../data';
import Card from './Card';

const Styles = theme => ({
    root: {
        width: '100vw',
        padding: '4rem 0 4rem 0', 
        // overflow: 'scroll'
    },
    grid: {
        textAlign: 'center',
    },
    icon: {
        textTransform: 'uppercase',
        marginTop: '1rem',
        marginBottom: '1rem'
    },
    profilePic: {
        border: '10px solid gray',
        height: '250px',
        borderRadius: '50%',
        boxShadow: '0px 10px 10px -4px rgba(0,0,0,0.2), 0px 10px 12px 0px rgba(0,0,0,0.14), 0px 2px 16px 0px rgba(0,0,0,0.12)'
    },
    portfolioContainer: {
        display: 'flex', 
        justifyContent: 'space-evenly',
    },
    portfolioGridItem: {
        padding: '1rem',
        margin: '.5rem 1rem 1rem 1rem ',
        // border: '1px solid black',
        // borderRadius: '20px',
        // width: '250px',
        // height: '180px'
    }
  });

 class Portfolio extends Component {
     constructor(){
         super()
         this.state = {
            filter: '',
            items: [...Data]
         }
     }

    componentDidMount = () => {
        this.props.getCurrentRoute(this.props.location.pathname)
    }

    // renderItems = () => {
    //     let items = this.state.items;
    //     return this.state.filter !== '' ? items.filter( item => item.role === this.state.filter) : '' 
    // }

    applyFilter = filter => {
       const filteredItems = Data.filter(item => {
          return filter === '' ? item : item.type === filter
        })
       this.setState({
           items: filteredItems
       })
    }

    render() {

        const {classes} = this.props;
        const { items } = this.state

        const renderItems =  items.map( (entry,i) => {
            return (
                <Grid item md={3} xs={12} key={entry.id} className={classes.portfolioGridItem} >
                    <Card key={entry.id} 
                        project={entry.project} 
                        type={entry.type}
                        image={entry.image}
                        link={entry.link} 
                        description={entry.description}   
                        />
                </Grid>
                )
            } 
        )

        return (
            <div className={classes.root}>
                <Container>
                    <Grid container className={classes.grid}>
                        <Grid item xs={12}>
                            <Typography variant="h3" component="h5" style={{padding: '2rem'}}>PORTFOLIO</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <hr/>
                        </Grid>
                        <Grid item xs={12}>
                            <Button type='button' onClick={() => this.applyFilter('')}>Show All</Button>
                            <Button type='button' onClick={() => this.applyFilter('Web Apps')}>Web Apps</Button>
                            <Button type='button' onClick={() => this.applyFilter('Games')}>Games</Button> 
                            <Button type='button' onClick={() => this.applyFilter('Videos')}>Videos</Button> 
                            <Button type='button' onClick={() => this.applyFilter('Logo')}>Logo</Button> 
                        </Grid> 
                        <Grid item xs={12}>
                            <Grid container className={classes.portfolioContainer}>
                                {renderItems}
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </div>
        )
    }
}

export default withStyles(Styles)(Portfolio)