const skillsData = [
    {
        id: 1,
        type: 'HTML',
        value: 80
    },
    {
        id: 2,
        type: 'CSS',
        value: 80
    },
    {
        id: 3,
        type: 'JavaScript',
        value: 70
    },
    {
        id: 4,
        type: 'React.js',
        value: 65
    },
    {
        id: 5,
        type: 'Redux',
        value: 40
    },
    {
        id: 6,
        type: 'Google Firebase',
        value: 60
    },
    {
        id: 7,
        type: 'Node.js',
        value: 40
    },
    {
        id: 8,
        type: 'postgreSQL',
        value: 30
    },
    {
        id: 9,
        type: 'Express.js',
        value: 40
    },
    {
        id: 10,
        type: 'UI-Design',
        value: 70
    },
    {
        id: 11,
        type: 'Adobe CC',
        value: 80
    },
    {
        id: 12,
        type: 'Wordpress',
        value: 70
    },

]

export default skillsData