import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Container, Grid, Typography } from '@material-ui/core'; 
import JourneyImage from '../img/journey.jpg'


const Styles = theme => ({
    root: {
        width: '100vw',
        padding: '4rem 0 4rem 0', 
        // overflow: 'scroll'
    },
    grid: {
        textAlign: 'center',
    },
    icon: {
        textTransform: 'uppercase',
        marginTop: '1rem',
        marginBottom: '1rem'
    },
    profilePic: {
        border: '5px solid black',
        height: '200px',
        borderRadius: '20px',
        marginTop: '2rem',
        boxShadow: '0px 10px 10px -4px rgba(0,0,0,0.2), 0px 10px 12px 0px rgba(0,0,0,0.14), 0px 2px 16px 0px rgba(0,0,0,0.12)'
    },
    
  });

 class About extends Component {
    componentDidMount = () => {
        this.props.getCurrentRoute(this.props.location.pathname)
    }
    render() {

        const {classes} = this.props;

        return (
            <div className={classes.root}>
                <Container>
                    <Grid container className={classes.grid}>
                        <Grid item xs={12}>
                            <Typography variant="h3" component="h5" style={{padding: '2rem'}}>CONTACT</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <hr/>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h5" component="h5" style={{padding: '2rem', textTransform: 'uppercase'}}>Interested In Working With Me?</Typography>
                            <Typography variant="body1" component="p" style={{padding: '.5rem', textTransform: 'uppercase'}}>
                                <a href='mailto:adeel3786@gmail.com' style={{textDecoration: 'underline', color: 'inherit'}} >Email Me</a>, and let's get started!
                            </Typography>
                            
                                <img className={classes.profilePic} src={JourneyImage} alt='Journey Is On'/>
                                <p style={{textTransform:'uppercase', fontSize: '.4rem', fontStyle: 'italic'}}>Photo by Ruediger Theiselmann on Unsplash</p>
                            
                        </Grid>
                    </Grid>
                </Container>
            </div>
        )
    }
}

export default withStyles(Styles)(About)