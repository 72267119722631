// import TravelIcon from '../img/travelinfoapplogo.jpg'

const data = [
    {
        id: 1,
        project: 'Travel Info App',
        type: 'Web Apps',
        description:` An application that gives real-time travel information. It gathers data from various transport API sources.`,
        image: 'https://image.freepik.com/free-vector/travel-bag-logo-vector_7315-3.jpg',
        link: 'https://iamadeelahmad.com/travelinfoapp/'
    },
    {
        id: 2,
        project: 'Image Recognition App',
        type: 'Web Apps',
        description:'An image recognition app designed with the Clarifai API, anaylses images for face and colour detection.',
        image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcTM7OfhCxjXcx4FpxsndgQsI66BXBzCu7gvs3vpJpB-F-Dp8iH6',
        link: 'https://adeel3786.github.io/facerecogapp/'
    },
    {
        id: 3,
        project: 'Booking App',
        type: 'Web Apps',
        description:`An example of booking app designed for a client, It shows the dashboard of the driving instructor as new bookings are placed. 
        Data is synced realtime with Google Firebase`,
        image: '',
        link: ''
    },
    {
        id: 4,
        project: 'Crash TV',
        type: 'Games',
        description:'A proto-type role-playing game I developed, in its entirety, at my time at WorkOut games. Developed on Unreal Engine and optimised for mobile.  ',
        image: '',
        link: ''
    },
    {
        id: 5,
        project: 'Color Rings',
        type: 'Games',
        description:'Another mobile game developed, also at my time at WorkOut Games. Aim is to go past as many rings as you can of the same colour. Developed on Unreal Engine.',
        image: '',
        link: ''
    },
    {
        id: 6,
        project: 'Taxi Tag Proto',
        type: 'Games',
        description:'A crazy taxi game, developed on Unreal Engine',
        image: '',
        link: ''
    },
    {
        id: 7,
        project: 'Weather App',
        type: 'Web Apps',
        description:'A small weather app, developed in React using Material-UI design',
        image: '',
        link: ''
    },
    {
        id: 8,
        project: 'Chat App',
        type: 'Web Apps',
        description:'',
        image: '',
        link: ''
    },
    {
        id: 9,
        project: 'GBMP',
        type: 'Logo',
        description:'A logo I designed for my company. Green Bird Media Productions',
        image: '',
        link: ''
    },
    {
        id: 10,
        project: 'The Hamper Factory',
        type: 'Logo',
        description:'A logo I designed for a client, using Adobe Illustrator and Photoshop',
        image: '',
        link: ''
    },
    {
        id: 11,
        project: 'Living Impulse',
        type: 'Logo',
        description:'A logo I designed for a client, using Adobe Illustrator and Photoshop',
        image: '',
        link: ''
    },
    {
        id: 12,
        project: 'White Feather Games',
        type: 'Logo',
        description:'A logo I designed for my indie game company, using Adobe Illustrator and Photoshop',
        image: '',
        link: ''
    },
    
]

export default data