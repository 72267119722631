import React from 'react';
import RightArrow from '@material-ui/icons/PlayArrow';
import {withRouter } from 'react-router-dom';
import * as ROUTES from '../Contants/Routes' 
import { withStyles } from '@material-ui/core/styles';
import { AppBar } from '@material-ui/core';

const Styles = theme => ({
    appBar: {
      top: 'auto',
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
      padding: '.5rem 0 .5rem 0'
    },
    toolbar: {
        display: 'grid',
        gridTemplateColumns: 'auto auto',
        gridTemplateRows: 'auto',
        gridTemplateAreas: 'left right'
    },
    rightArrow: {
        fontSize: '2.5rem', 
        borderRadius: '15px',
        color: 'white',
    }
  });

class NavArrows extends React.Component {
    constructor(){
        super()
        this.state = {
            currentRoute: ''
        }
    }

    componentDidMount = () => {
        this.setState({currentRoute: this.props.currentRoute})
    }

    static getDerivedStateFromProps = (nextProps, prevState) => {
        if(nextProps.currentRoute !== prevState.currentRoute) { 
            return { currentRoute: nextProps.currentRoute } 
        } else {
            return null
        }
    }

    // componentDidUpdate = (prevProps, prevState ) => {
    //     this.props.currentRoute === this.state.currentRoute ? console.log('YES') : console.log('No')
    // }

    navigateLeft = (currentRoute) => {
        if(currentRoute === '/' ) {
            this.props.history.push(ROUTES.CONTACT)
        } else if (currentRoute === '/about' ) {
            this.props.history.push(ROUTES.HOME)
        } else if (currentRoute === '/skills' ) {
            this.props.history.push(ROUTES.ABOUT)
        } else if (currentRoute === '/experience' ) {
            this.props.history.push(ROUTES.SKILLS)
        } else if (currentRoute === '/portfolio' ) {
            this.props.history.push(ROUTES.EXPERIENCE)
        } else if (currentRoute === '/contact' ) {
            this.props.history.push(ROUTES.PORTFOLIO)
        }
    }

    navigateRight = (currentRoute) => {
        if(currentRoute === '/' ) {
            this.props.history.push(ROUTES.ABOUT)
        } else if (currentRoute === '/about' ) {
            this.props.history.push(ROUTES.SKILLS)
        } else if (currentRoute === '/skills' ) {
            this.props.history.push(ROUTES.EXPERIENCE)
        } else if (currentRoute === '/experience' ) {
            this.props.history.push(ROUTES.PORTFOLIO)
        } else if (currentRoute === '/portfolio' ) {
            this.props.history.push(ROUTES.CONTACT)
        } else if (currentRoute === '/contact' ) {
            this.props.history.push(ROUTES.HOME)
        }
    }

    navArrow = direction => {
        const currentRoute = this.state.currentRoute;
        switch (direction) {
            case 'left':
                this.navigateLeft(currentRoute)
                break;

            case 'right':
                this.navigateRight(currentRoute)
                break;

            default:
                return null
        }
    }

    render(){

        const { classes } = this.props;

        
    
        return(
            <AppBar position="fixed" color="primary" className={classes.appBar}>
                {/* <Toolbar > */}
                    <div className={classes.toolbar}>
                        <RightArrow 
                            onClick={()=> this.navArrow('left')} 
                            style={{
                            transform: 'scaleX(-1)',
                            margin: '0 auto'
                            }}
                            className={classes.rightArrow}
                        />
                        <RightArrow 
                            onClick={()=> this.navArrow('right')} 
                            className={classes.rightArrow}
                            style={{
                               margin: '0 auto'
                            }}
                        />
                    </div>
                {/* </Toolbar> */}
            </AppBar>
        )
    }
}

export default withStyles(Styles)(withRouter(NavArrows));

