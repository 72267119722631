import React from 'react';
import { Link } from 'react-router-dom';
import * as ROUTES from '../Contants/Routes';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import HomeIcon from '@material-ui/icons/HomeOutlined';
import FaceIcon from '@material-ui/icons/Face';
import SkillsIcon from '@material-ui/icons/GradeOutlined'
import CardTravelIcon from '@material-ui/icons/CardTravelOutlined';
import PortfolioIcon from '@material-ui/icons/BrushOutlined';
import ContactIcon from '@material-ui/icons/EmailOutlined';
import './Nav.css'


const Styles = theme => ({
  root: {
    position: 'fixed',
    zIndex: '1'
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  appBar: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    width: '100vw'
  },
  miniAppBarRoot: {
    display: 'flex',
    zIndex: theme.zIndex.drawer + 1,
    width: '100vw'
  },
  miniAppBar: {
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
  },
  miniToolBar: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    alignContent: 'flex-end'
},
  toolbar: {
      width: '50%',
      padding: '0.5rem',
  },
  link: {
    color: 'white',
    textDecoration: 'none',
    textTransform: 'uppercase'
  },
  list : {
    width: '100vw',
    color: 'white',
    marginTop: '2rem',
    textTransform: 'uppercase',

  },
  
  drawerRoot: {
    flexShrink: 0,
  },
  drawerPaper : {
    backgroundColor: 'rgba(0,0,0,0.8)',
    height: 'auto',
    
  }
});

class Navigation extends React.Component {
  constructor(){
    super()
    this.state = {
      sideDrawerOpen: false
    }
  }

  toggleDrawer = (open) => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    this.setState({sideDrawerOpen: open})
  };

  render(){
    const { classes, width } = this.props;
    const isSmallScreen = isWidthDown('md', width)

    const sideList = ()=> (
      <div
        className={classes.list}
        role="presentation"
        onClick={this.toggleDrawer(false)}
        onKeyDown={this.toggleDrawer(false)}
      >
        <List className={classes.list}>
            <ListItem button>
              <ListItemIcon style={{color: 'white'}}><HomeIcon/></ListItemIcon>
              <ListItemText>
                <Link className={classes.link} to={ROUTES.HOME}>Home</Link>
              </ListItemText>
            </ListItem>
            <ListItem button>
              <ListItemIcon style={{color: 'white'}}><FaceIcon/></ListItemIcon>
              <ListItemText>
                <Link className={classes.link} to={ROUTES.ABOUT}>About Me</Link>
              </ListItemText>
            </ListItem>
            <ListItem button>
              <ListItemIcon style={{color: 'white'}}><SkillsIcon/></ListItemIcon>
              <ListItemText>
                <Link className={classes.link} to={ROUTES.SKILLS}>Skills</Link>
              </ListItemText>
            </ListItem>
            <ListItem button>
              <ListItemIcon style={{color: 'white'}}><CardTravelIcon/></ListItemIcon>
              <ListItemText>
                <Link className={classes.link} to={ROUTES.EXPERIENCE}>Experience</Link>
              </ListItemText>
            </ListItem>
            <ListItem button>
              <ListItemIcon style={{color: 'white'}}><PortfolioIcon/></ListItemIcon>
              <ListItemText>
                <Link className={classes.link} to={ROUTES.PORTFOLIO}>Portfolio</Link>
              </ListItemText>
            </ListItem>
            <ListItem button>
              <ListItemIcon style={{color: 'white'}}><ContactIcon/></ListItemIcon>
              <ListItemText>
                <Link className={classes.link} to={ROUTES.CONTACT}>Contact Me</Link>
              </ListItemText>
            </ListItem>
        </List>
        </div>
    );

    const smallAppBar = (<div className={classes.miniAppBarRoot}>
    <AppBar position="static" className={classes.miniAppBar} >
        <Toolbar>
          <div className={classes.miniToolBar}>
          <IconButton onClick={this.toggleDrawer(true)} edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
            <MenuIcon  />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            ADEEL AHMAD
          </Typography>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer 
          classes={{root: classes.drawerRoot , paper: classes.drawerPaper}}
          open={this.state.sideDrawerOpen} 
          onClose={this.toggleDrawer(false)}
         
          >
            {sideList()} 
        </Drawer>
      </div>)

    const bigAppBar = 
    <AppBar position="static" className={classes.appBar}>
      <div style={{marginLeft: '2rem'}}>
          <Typography variant='h4'>ADEEL AHMAD</Typography>
      </div>
      <Toolbar className={classes.toolbar}>
        <Typography variant="h6" className={classes.title}>
          <Link className={classes.link} to={ROUTES.HOME}>Home</Link>
        </Typography>
        <Typography variant="h6" className={classes.title}>
          <Link className={classes.link} to={ROUTES.ABOUT}>About Me</Link>
        </Typography>
        <Typography variant="h6" className={classes.title}>
          <Link className={classes.link} to={ROUTES.SKILLS}>Skills</Link>
        </Typography>
        <Typography variant="h6" className={classes.title}>
          <Link className={classes.link} to={ROUTES.EXPERIENCE}>Experience</Link>
        </Typography>
        <Typography variant="h6" className={classes.title}>
          <Link className={classes.link} to={ROUTES.PORTFOLIO}>Portfolio</Link>
        </Typography>
        <Typography variant="h6" className={classes.title}>
          <Link className={classes.link} to={ROUTES.CONTACT}>Contact Me</Link>
        </Typography>
        </Toolbar>
    </AppBar>

  return (
      <div className={classes.root}>
        { isSmallScreen? smallAppBar : bigAppBar }
        
      </div>
    );
  }
}

export default withWidth()(withStyles(Styles)(Navigation))
