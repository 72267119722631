import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Container, Grid, Typography, Icon, Button } from '@material-ui/core'; 
import DrivenIcon from '@material-ui/icons/DoubleArrow';
import HighQualityIcon from '@material-ui/icons/CheckBox';
import CreativeIcon from '@material-ui/icons/Create';
import CleanIcon from '@material-ui/icons/ViewModule';
import QuoteIcon from '@material-ui/icons/FormatQuote';
import ProfilePic from '../img/adeelahmad.jpg';


const Styles = theme => ({
    root: {
        width: '100vw',
        padding: '4rem 0 4rem 0', 
        // overflow: 'scroll'
    },
    grid: {
        textAlign: 'center',
    },
    icon: {
        textTransform: 'uppercase',
        marginTop: '1rem',
        marginBottom: '1rem'
    },
    profilePic: {
        border: '10px solid gray',
        height: '250px',
        borderRadius: '50%',
        boxShadow: '0px 10px 10px -4px rgba(0,0,0,0.2), 0px 10px 12px 0px rgba(0,0,0,0.14), 0px 2px 16px 0px rgba(0,0,0,0.12)'
    },
    
  });

 class About extends Component {
    componentDidMount = () => {
        this.props.getCurrentRoute(this.props.location.pathname)
    }
    render() {

        const {classes} = this.props;

        return (
            <div className={classes.root}>
                <Container>
                    <Grid container className={classes.grid}>
                        <Grid item xs={12}>
                            <Typography variant="h3" component="h5" style={{padding: '2rem'}}>ABOUT</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <hr/>
                        </Grid>
                        <Grid item md={3} xs={6} className={classes.icon} >
                            <Icon >
                                <DrivenIcon fontSize='large'/>
                                <Typography >Driven</Typography>
                            </Icon>
                        </Grid>
                        <Grid item md={3} xs={6} className={classes.icon}>
                            <Icon >
                                <CreativeIcon fontSize='large'/>
                                <Typography>Creative</Typography>
                            </Icon>
                        </Grid>
                        <Grid item md={3} xs={6} className={classes.icon}>
                            <Icon>
                                <CleanIcon fontSize='large'/>
                                <Typography>Clean</Typography>
                            </Icon>
                        </Grid>
                        <Grid item md={3} xs={6} className={classes.icon}>
                            <Icon>
                                <HighQualityIcon fontSize='large'/>
                                <Typography>High Quality</Typography>
                            </Icon>
                        </Grid>
                        <Grid item xs={12}>
                            <hr />
                        </Grid>
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '1.5rem'}}>
                            <Grid item md={6} xs={12}>
                                    <img className={classes.profilePic}  src={ProfilePic} alt='Adeel Ahmad'/>
                            </Grid>
                            <Grid item md={8} xs={8} style={{marginTop: '2rem'}}>
                                <Typography align='justify' style={{marginBottom: '2rem'}}>
                                    <Icon><QuoteIcon fontSize='large' style={{transform: 'rotateY(180deg)'}}/></Icon>The journey started as a free-lance designer back in 2012. 
                                    Since then, I have collaborated with many organisations, worked with many clients remotely, 
                                    I've also had my work broadcasted on multiple national and international SKY television channels. <br/><br/>
                                    I have founded a design company, worked at a game publishing company, initially as a prototype designer and then transformed into a web developer.<br/><br/>
                                    I'm constanlty looking to improve and learn more by working on my own personal web/app development projects.
                                    I have a diverse set of skills ranging from graphic design, web design, web development, professional video production, 
                                    mobile game development to even 3d modelling.
                                </Typography>
                                <hr/>
                            </Grid>
                        </div>
                        <Grid item xs={12} style={{marginTop: '1rem', marginBottom: '2rem'}}>
                        <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
                            <div>
                                <Button variant='outlined' href='https://www.linkedin.com/in/adeel3786' target='new'>LinkedIn</Button>
                            </div>
                            <div>
                                <Button variant='outlined' href='mailto:adeel3786@gmail.com'>Work With Me?</Button>
                            </div>
                        </div>
                        </Grid>
                    </Grid>
                </Container>
            </div>
        )
    }
}

export default withStyles(Styles)(About)