import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const Styles = ({
  card: {
    width: 'auto',
  },
  media: {
    height: 150,
    marginTop: '1rem',
    paddingTop: '1rem'
  },
});

class CardComponent extends React.Component {
    render(){

        const { classes, project, link, description, image } = this.props;

        return (
            <Card className={classes.card}>
                <CardActionArea>
                    <CardMedia
                        className={classes.media}
                        image={ image === '' ? "/static/images/cards/contemplative-reptile.jpg" : image}
                        title={project}
                    />
                    <CardContent>
                        <Typography gutterBottom variant="button" component="h6">
                            {project}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p">
                            {description}
                        </Typography>
                    </CardContent>
                </CardActionArea>
                <CardActions>
                    <Button target='_blank' href={link} size="small" color="primary">
                        View Here
                    </Button>
                </CardActions>
            </Card>
        );
    }
}

export default withStyles(Styles)(CardComponent)